import Cookies from 'js-cookie';
import { APP_CACHE_STORAGE_DRIVER } from '../config';

export default {
  getUserName(): string | null {
    const storage = window[APP_CACHE_STORAGE_DRIVER];
    return storage.getItem('username');
  },
  setUserName(username: string) {
    console.log(`storage.service setUserName: ${username}`);
    const storage = window[APP_CACHE_STORAGE_DRIVER];
    storage.setItem('username', username);
  },
  removeUserName() {
    const storage = window[APP_CACHE_STORAGE_DRIVER];
    storage.removeItem('username');
  },
  setJwtCookie(jwt: string) {
    // set jwt should be httpOnly
    Cookies.set('jwt', jwt, {
      path: '/',
      sameSite: 'none',
      secure: true,
      expires: 26000,
      // TODO: should eventually make this httpOnly
      // httpOnly: true,
    });
  },
  getJwtCookie(): string | undefined {
    return Cookies.get('jwt');
  },
  removeJwtCookie() {
    Cookies.remove('jwt');
  },

  getItem(key: string) {
    const storage = window[APP_CACHE_STORAGE_DRIVER];
    return storage.getItem(key);
  },
  setItem(key: string, value: string) {
    const storage = window[APP_CACHE_STORAGE_DRIVER];
    return storage.setItem(key, value);
  },
  removeItem(key: string) {
    const storage = window[APP_CACHE_STORAGE_DRIVER];
    return storage.removeItem(key);
  },
};
