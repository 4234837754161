export const APP_CACHE_STORAGE_DRIVER = 'localStorage'; // 'sesionStorage' | 'localStorage'

export const CUSTOM_TEXT_CHARACTER_LIMIT = process.env.REACT_APP_CUSTOM_TEXT_CHARACTER_LIMIT || 150;

export const WIDGET_URL = process.env.REACT_APP_WIDGET_URL || '';

export const FEATURE_FLAGS = {
  isLateFeesCustomTextEnabled:
    process.env.REACT_APP_ENABLE_LATE_FEES_CUSTOM_TEXT === 'true',
  isCustomerFeesCustomTextEnabled:
    process.env.REACT_APP_ENABLE_CUSTOMER_FEES_CUSTOM_TEXT === 'true',
};

export const TINYMCE_API_KEY = process.env.REACT_APP_TINYMCE_API_KEY;
