export class ThemeDetails {
  public logo: string;

  public leftSidebarBgColor: string;

  public leftSidebarActiveColor: string;

  public leftSidebarHoverColor: string;

  public leftSidebarTextColor: string;

  public topBarTextColor: string;

  public topBarBgColor: string;

  constructor({
    logo,
    leftSidebarBgColor,
    leftSidebarActiveColor,
    leftSidebarHoverColor,
    leftSidebarTextColor,
    topBarTextColor,
    topBarBgColor,
  }: {
    logo: string;
    leftSidebarBgColor: string;
    leftSidebarActiveColor: string;
    leftSidebarHoverColor: string;
    leftSidebarTextColor: string;
    topBarTextColor: string;
    topBarBgColor: string;
  }) {
    this.logo = logo;
    this.leftSidebarBgColor = leftSidebarBgColor;
    this.leftSidebarActiveColor = leftSidebarActiveColor;
    this.leftSidebarHoverColor = leftSidebarHoverColor;
    this.leftSidebarTextColor = leftSidebarTextColor;
    this.topBarTextColor = topBarTextColor;
    this.topBarBgColor = topBarBgColor;
  }
}