import React from 'react';
import './TextArea.css';
import { useTranslation } from 'react-i18next';
import { composeClasses } from 'libs/utils';

interface ITextArea
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  value: string;
  maxLength: number;
  showCounter?: boolean;
}

export enum InputStyling {
  // Minimal = 'bottom-border-only',
  Full = 'full-borders-textarea',
  // Vanilla = 'vanilla-border'
}

const calculatedValue = (value: string, maxLength: number): string => {
  return value.slice(0, maxLength);
};

const TextArea: React.FC<ITextArea> = ({
  value = '',
  maxLength = 0,
  showCounter = false,
  ...props
}) => {
  const { t } = useTranslation();
  const charactersLeft = maxLength && maxLength - calculatedValue(value, maxLength).length;

  return (
    <div
      className={composeClasses(
        InputStyling.Full,
        'textareaContainer',
        !charactersLeft && 'textareaErrorStyle',
      )}
    >
      <textarea
        className="textarea"
        id=""
        name="you"
        {...props}
        value={calculatedValue(value, maxLength)}
      />
      {showCounter && (
        <span
          className={composeClasses(
            'textareaCounter',
            !charactersLeft && 'maxLimitStyle',
          )}
        >
          {charactersLeft} {t('Characters_Left')}
        </span>
      )}
    </div>
  );
};

export default TextArea;
