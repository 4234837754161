import React, { FC } from 'react';
import { composeClasses } from '../../../libs/utils';
import './TypoGraphy.css';

interface IOpttyTypoGraphy {
  children?: React.ReactNode;
  className?: string;
  prominence?: 'super' | 'jumbotron';
  level?: number;
  text?: string;
}

const Typography: FC<IOpttyTypoGraphy> = ({ level, text, ...props }) => {
  const headingTag = React.createElement(
    `h${level || 1}`,
    Object.assign(props, {
      className: composeClasses(props.className, props.prominence),
      prominence: undefined,
    }),
    props.children || text || '',
  );
  return headingTag;
};

export default Typography;
