export default function noConsole() {
  if (process.env.NODE_ENV === 'production') {
    const noShow = () => {};
    [
      'assert',
      'clear',
      'count',
      'debug',
      'dir',
      'dirxml',
      'error',
      'exception',
      'group',
      'groupCollapsed',
      'groupEnd',
      'info',
      'log',
      'markTimeline',
      'profile',
      'profileEnd',
      'table',
      'time',
      'timeEnd',
      'timeline',
      'timelineEnd',
      'timeStamp',
      'trace',
      'warn',
    ].forEach((method) => {
      window.console[method] = noShow;
    });
  }
}
