import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, dummy } from '../libs/utils/i18n';

export function initialize() {
  i18next.use(initReactI18next).init({
    resources: {
      en,
      dummy,
    },
    lng: 'dummy',
    fallbackLng: 'en',
  });
}

export async function changeLanguage(language: string) {
  await i18next.changeLanguage(language);
  return true;
}
