// eslint-disable-next-line max-classes-per-file
import { OnboardingForm } from 'components/Onboarding/OnboardingFormClass';
import { InvoicingDetails } from './InvoicingDetailsDto';
import { ThemeDetails } from './ThemeDetailsDto';

export class OnboardFamilyEntityDto {
  public readonly name: string;

  public readonly invoicingDetails: InvoicingDetails | null = null;

  public readonly contactDetails: { financeEmail: string
    companyNumber: string } | null = null;

  public readonly parentId: string | null = null;

  public readonly heirachyLevel: string;

  public readonly theme: ThemeDetails;

  public readonly country: string | null;

  public readonly firstName: string;

  public readonly lastName: string;

  public readonly legalName: string;

  public readonly userEmail: string;

  public readonly redirectUrl: string;

  public readonly UEN: string;

  public readonly gstRegistration: string;

  constructor(data: OnboardingForm, hasParent: boolean) {
    this.name = data.name;
    this.legalName = data.legalName;
    this.heirachyLevel = data.heirachyLevel;
    this.userEmail = data.userEmail;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.UEN = data.singaporeEntityUEN;
    this.gstRegistration = data.gstRegistrationNumber;
    this.theme = new ThemeDetails({
      logo: data.image,
      leftSidebarBgColor: data.leftSidebarBgColour,
      leftSidebarActiveColor: data.leftSidebarActiveColour,
      leftSidebarHoverColor: data.leftSidebarHoverColour,
      leftSidebarTextColor: data.leftSidebarTextColour,
      topBarTextColor: data.topBarTextColour,
      topBarBgColor: data.topBarBgColour,
    });
    this.redirectUrl = data.redirectUrl;
    this.country = data.country;
    if (!hasParent) {
      this.invoicingDetails = new InvoicingDetails({
        currency: data.currency,
        minTransactionCharge: data.minTransactionCharge,
        txnCost: data.txnCost,
        successfulCharged: data.successfulCharged,
        declinedCharged: data.declinedCharged,
        refundCharged: data.refundCharged,
        timeoutCharged: data.timeoutCharged,
        gstApplicable: OnboardFamilyEntityDto.gstRegistration(data.country ? data.country : '', data.singaporeEntityUEN, data.gstRegistrationNumber),
      });
      this.contactDetails = {
        financeEmail: data.financeEmail,
        companyNumber: data.companyNumber,
      };
    } else {
      this.parentId = data.parentId;
    }
  }

  public static gstRegistration(country: string, singaporeEntityUEN: string, gstRegistrationNumber: string) {
    if (country === 'SG') {
      return true;
    } if (singaporeEntityUEN !== '' && gstRegistrationNumber === '') {
      return true;
    }

    return false;
  }
}
