import React, { FunctionComponent } from 'react';
import './DashboardPagesLayout.css';

import SideBar from './SideBar/SideBar';

const DashboardPagesLayout: FunctionComponent = (props: { children?: React.ReactNode }) => {
  return (
    <>
      <main className="main-content">
        <div className="sidebar-wraper">
          <div className="sidebar-content">
            <SideBar />
          </div>
        </div>

        <div className="dashboard-content">
          <article>{props.children}</article>
        </div>
      </main>
    </>
  );
};

export default DashboardPagesLayout;
