import { TxnCost } from './TxnCostDto';

export class InvoicingDetails {
  public currency: string;

  public txnCost: TxnCost[];

  public successfulCharged: boolean;

  public declinedCharged: boolean;

  public refundCharged: boolean;

  public timeoutCharged: boolean;

  public gstApplicable: boolean;

  public minTransactionCharge: boolean;

  constructor({
    currency,
    txnCost,
    successfulCharged,
    declinedCharged,
    refundCharged,
    timeoutCharged,
    gstApplicable,
    minTransactionCharge,
  }: {
    currency: string;
    txnCost: TxnCost[];
    successfulCharged: boolean;
    declinedCharged: boolean;
    refundCharged: boolean;
    timeoutCharged: boolean;
    gstApplicable: boolean;
    minTransactionCharge: boolean;
  }) {
    // TODO: any type checking here
    this.currency = currency;
    this.txnCost = txnCost;
    this.successfulCharged = successfulCharged;
    this.declinedCharged = declinedCharged;
    this.refundCharged = refundCharged;
    this.timeoutCharged = timeoutCharged;
    this.gstApplicable = gstApplicable;
    this.minTransactionCharge = minTransactionCharge;
  }
}