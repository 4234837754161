import React from 'react';
import './OpttyScreenLoader.css';
import loaderImage from '../../../assets/images/gif/loader.gif';

const OpttyScreenLoader = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <div>
      {isLoading && (
        <div className="screen-loader">
          <img
            alt="Loading..."
            src={loaderImage}
          />
        </div>
      )}
    </div>
  );
};

export default OpttyScreenLoader;
