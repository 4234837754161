import React, { FC } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import './SingleLineTextInput.css';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import FileCopy from '@material-ui/icons/FileCopyOutlined';
import { composeClasses } from '../../../libs/utils';

export type SingleLineTextInputTypes =
  | 'date'
  | 'text'
  | 'search'
  | 'range'
  | 'password'
  | 'number'
  | 'url';

export enum InputStyling {
  Minimal = 'bottom-border-only',
  Full = 'full-borders',
  Vanilla = 'vanilla-border'
}

export enum CursorStyling {
  Dropdown = 'dropdown-pointer',
  Input = 'input-pointer',
}

export enum InputDisplay {
  FullStretch = 'inp-block',
  Contained = 'inp-inline',
}

type Direction = 'upward' | 'downward';
interface IOpttySingleLineTextInput
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  styling?: InputStyling;
  display?: InputDisplay;
  hasError?: boolean;
  hasSuccess?: boolean;
  useAsDropDown?: boolean;
  dropDownDirection?: Direction;
  cursor?: CursorStyling;
  prefix?: string;
  suffix?: string;
  copy?: boolean;
  copyTextToClipboard?: any
}

const SingleLineTextInput: FC<IOpttySingleLineTextInput> = ({
  tabIndex = 0,
  type = 'text',
  styling = InputStyling.Minimal,
  display = InputDisplay.FullStretch,
  hasError = false,
  hasSuccess = false,
  useAsDropDown = false,
  dropDownDirection = 'downward',
  cursor = CursorStyling.Input,
  prefix = '',
  suffix = '',
  copy = false,
  copyTextToClipboard,
  ...props
}) => {
  const inputType = type as SingleLineTextInputTypes;

  return (
    <div className="input-box">
      {prefix !== '' && <span className="input-prefix">{prefix}</span>}
      <input
        className={composeClasses(
          'input',
          styling,
          display,
          cursor,
          props.className,
          hasError ? 'has-error' : '',
        )}
        tabIndex={typeof tabIndex !== 'undefined' ? tabIndex : -1}
        type={inputType}
        {...props}
      />
      {suffix !== '' && <span className="input-suffix">{suffix}</span>}
      {hasSuccess && (
        <span className="input-success">
          <CheckCircleIcon />
        </span>
      )}

      {useAsDropDown && (
        <span className="input-dropdown">
          {dropDownDirection === 'upward' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
        </span>
      )}
      {copy && (
        <span className="copy">
          <FileCopy onClick={() => copyTextToClipboard(props.value)} />
        </span>
      )}
    </div>
  );
};

export default SingleLineTextInput;
