import { CredentialResponse } from '@react-oauth/google';
import storageService from './storage.service';
import backendService from './backend.service';

export interface IUser {
  name: string;
  email: string;
}

export const checkAuthenticated = async (jwt: any): Promise<boolean> => {
  // TODO: hit backend endpoint that verifies jwt with google clientId and checks user exists
  return backendService.checkAuthenticated(jwt);
};

// login with jwt from google workspace and return the name
export async function login(credentialResponse: CredentialResponse): Promise<IUser | null> {
  // TODO: hit backend that verifies jwt with google clientId and adds to database if dosent exist
  console.log('start login');
  const jwt = credentialResponse.credential;
  if (!jwt) {
    // TODO: handle no jwt
    return Promise.resolve(null);
  }

  console.log('sent login request');
  const info: IUser | undefined = await backendService.login(jwt);
  console.log(`info: ${info}`);
  if (!info) {
    throw new Error('Error in loggin, wierd becuase should be valid through gworkspace');
  }

  storageService.setUserName(info.name);
  storageService.setJwtCookie(jwt);

  return Promise.resolve(info);
}

export async function logout() {
  storageService.removeJwtCookie();
  storageService.removeUserName();
}

export const authService = {
  login,
  checkAuthenticated,
  logout,
};
