import React, { useEffect, useState } from 'react';
// import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteComponentProps,
  Redirect,
  useHistory,
} from 'react-router-dom';
import './App.css';
import NotFoundErrorPage from 'routes/NotFoundErrorPage';
import storageService from 'services/storage.service';
import AccessPagesLayout from './components/layouts/AccessPagesLayout';
import DashboardPagesLayout from './components/layouts/DashboardPagesLayout';
import { asyncComponent } from './libs/helpers';

import { checkAuthenticated } from './services/auth.service';

const LoginPage = asyncComponent(() => import('routes/Login'));
const OnboardingMerchantPage = asyncComponent(() => import('routes/onboarding/OnboardingMerchant'));
const AuditLogPage = asyncComponent(() => import('routes/AuditLog'));
const PaymentsPage = asyncComponent(() => import('routes/PaymentMethodPage'));
const InvoicingPage = asyncComponent(() => import('routes/invoicing/InvoicingDetails'));
const CommissionLinkPage = asyncComponent(() => import('routes/invoicing/CommissionLink'));
const CommissionsPage = asyncComponent(() => import('routes/invoicing/Commissions'));
const OnboardingParentPage = asyncComponent(() => import('routes/onboarding/OnboardingParent'));
const OnboardingGrandparentPage = asyncComponent(() => import('routes/onboarding/OnboardingGrandparent'));
const InvoiceGeneratePage = asyncComponent(() => import('routes/invoicing/InvoiceGenerate'));

function App() {
  const [authenticated, setAuthenticated] = useState(true);
  // const { isAuthenticated, refreshAuthenticated } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    // JWT cookie found, include it in your HTTP requests
    const jwt = storageService.getJwtCookie() || '';
    checkAuthenticated(jwt).then((data) => {
      setAuthenticated(data);
    });
  }, [history]);

  return (
    <Router>
      <Switch>
        <Route path="/">
          {authenticated ? (
            <DashboardPagesLayout>
              <Switch>
                <Route
                  exact
                  path={['/', '/app', '/app/onboarding', '/app/onboarding/child']}
                  render={(routeProps: RouteComponentProps) => {
                    return <OnboardingMerchantPage {...routeProps} />;
                  }}
                />
                <Route
                  exact
                  path={['/app/onboarding/parent']}
                  render={(routeProps: RouteComponentProps) => {
                    return <OnboardingParentPage {...routeProps} />;
                  }}
                />
                <Route
                  exact
                  path={['/app/onboarding/grandparent']}
                  render={(routeProps: RouteComponentProps) => {
                    return <OnboardingGrandparentPage {...routeProps} />;
                  }}
                />
                <Route
                  exact
                  path={['/app/auditLog']}
                  render={(routeProps: RouteComponentProps) => {
                    return <AuditLogPage {...routeProps} />;
                  }}
                />
                <Route
                  exact
                  path={['/app/auditLog']}
                  render={(routeProps: RouteComponentProps) => {
                    return <AuditLogPage {...routeProps} />;
                  }}
                />
                <Route
                  exact
                  path={['/app/paymentMethods']}
                  render={(routeProps: RouteComponentProps) => {
                    return <PaymentsPage {...routeProps} />;
                  }}
                />
                {/* TODO: update the components loaded here */}
                <Route
                  exact
                  path={['/app/invoicing/details']}
                  render={(routeProps: RouteComponentProps) => {
                    return <InvoicingPage {...routeProps} />;
                  }}
                />
                <Route
                  exact
                  path={['/app/invoicing/commissionLink']}
                  render={(routeProps: RouteComponentProps) => {
                    return <CommissionLinkPage {...routeProps} />;
                  }}
                />
                <Route
                  exact
                  path={['/app/invoicing/commissions']}
                  render={(routeProps: RouteComponentProps) => {
                    return <CommissionsPage {...routeProps} />;
                  }}
                />
                <Route
                  exact
                  path={['/app/invoicing/generate']}
                  render={(routeProps: RouteComponentProps) => {
                    return <InvoiceGeneratePage {...routeProps} />;
                  }}
                />
              </Switch>
              {/* <Redirect to="/app/onboarding" /> */}
            </DashboardPagesLayout>
          ) : (
            <>
              <AccessPagesLayout>
                <Switch>
                  <Route
                    exact
                    path="/login"
                    render={(routeProps: RouteComponentProps) => {
                        return <LoginPage {...routeProps} />;
                    }}
                  />
                  <Route component={NotFoundErrorPage} />
                </Switch>
              </AccessPagesLayout>
              <Redirect to="/login" />
            </>
          )}
        </Route>
        <Route component={NotFoundErrorPage} />
      </Switch>
    </Router>
  );
}

export default App;
