import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';

type ICheckbox = CheckboxProps;

const CustomCheckbox = withStyles({
  root: {
    color: '#000',
    '&$checked': {
      color: '#000',
    },
  },
  checked: {},
})((props: ICheckbox) => (
  <Checkbox
    color="default"
    {...props}
  />
));

export default CustomCheckbox;
